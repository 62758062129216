.artist-report {
  .top-bar {
    border-radius: 10px;
    padding: 0.5rem 1rem;
    background-color: white;
    display: flex;
    align-items: center;
    .search-input {
      padding: 1rem 1.5rem;
      width: 30%;
      font-size: 1rem;
      border: none;
      &:focus {
        outline: none;
      }
    }
    button {
      margin-left: 2rem;
    }

    .platform {
      text-transform: uppercase;
      font-size: 1.4rem;
      span {
        text-transform: initial;
        margin-left: 1rem;
        font-size: 1rem;
      }
    }
    .input {
      button {
        margin-left: 2rem;
      }
    }
  }

  .summary {
    display: flex;
    margin: 0.5rem;
    height: 4rem;
    .item {
      background-color: white;
      border-radius: 10px;
      height: 4rem;
      margin-right: 0.5rem;
      font-size: 0.6rem;
      padding: 0.5rem;
      .heading {
        opacity: 0.4;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
      }
      .value {
        font-size: 1rem;
      }
    }
  }

  .artist-summary {
    margin: 1rem 0;
    border: 2px solid white;
    border-radius: 10px;
    .top-bar {
      border-radius: 10px;
      padding: 0.5rem 1rem;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      .title {
        font-size: 1.5rem;
      }
      .platform {
        text-transform: uppercase;
        font-size: 1.4rem;
        span {
          text-transform: initial;
          margin-left: 1rem;
          font-size: 1rem;
        }
      }
    }

    .summary {
      display: flex;
      margin: 0.5rem;
      height: 4rem;
      .item {
        background-color: white;
        border-radius: 10px;
        height: 4rem;
        margin-right: 0.5rem;
        font-size: 0.6rem;
        padding: 0.5rem;
        .heading {
          opacity: 0.4;
          margin-bottom: 0.5rem;
          text-transform: uppercase;
        }
        .value {
          font-size: 1rem;
        }
      }
    }
  }
}
