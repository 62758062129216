@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
* {
  box-sizing: border-box;
}
p {
  margin: 0;
}
*.loading {
  &::before {
    content: "Loading..";
    position: absolute;
    width: 100%;
    height: inherit;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 100;
  }
}
.App {
  min-height: 100vh;
  background-color: #cde6f6;
}
.list-summary {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 60fr 40fr;
  gap: 1rem;
  align-items: flex-start;
}
.playlist {
  margin-top: 0rem;
  .top-bar {
    background-color: white;
    border-radius: 10px;
    display: flex;
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 2rem;
    align-items: center;
    justify-content: center;
    & > * {
      margin-right: 2rem;
    }

    .date-input {
      input {
        height: 53px;
      }
    }
  }

  &__details {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 1rem 0;
    img {
      width: 50px;
      height: 50px;
      margin-right: 1rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
  &__date {
    font-size: 1.1rem;
  }
}

.charts-1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.campaign-summary {
  margin: 1rem 0;
  border: 2px solid white;
  border-radius: 10px;
  .top-bar {
    border-radius: 10px;
    padding: 0.5rem 1rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 1.5rem;
    }
    .platform {
      text-transform: uppercase;
      font-size: 1.4rem;
      span {
        text-transform: initial;
        margin-left: 1rem;
        font-size: 1rem;
      }
    }
    .input {
      button {
        margin-left: 2rem;
      }
    }
  }

  .summary {
    display: flex;
    margin: 0.5rem;
    height: 4rem;
    .item {
      background-color: white;
      border-radius: 10px;
      height: 4rem;
      margin-right: 0.5rem;
      font-size: 0.6rem;
      padding: 0.5rem;
      .heading {
        opacity: 0.4;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
      }
      .value {
        font-size: 1rem;
      }
    }
  }
}

.chart-item {
  background-color: white;
  font-size: 0.7rem;
  padding: 1rem 1rem 1rem 0.5rem;

  .title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

.double-charts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
}

.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  &-wrapper {
    background-color: white;
    padding: 1rem;
    width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    position: relative;

    h3 {
      text-transform: uppercase;
      margin-bottom: 4rem;
    }
    .login-error {
      position: absolute;
      top: 4rem;
    }
    .input-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      label {
        text-transform: capitalize;
        margin-bottom: 0.5rem;
      }

      input {
        padding: 1rem 0.5rem;
        font-size: 1rem;
      }
    }
  }
}

.plalists-list {
  height: 628px;
  overflow: hidden;
  .search-input {
    display: flex;
    background: white;
    input {
      padding: 2rem 3rem;
      width: 60%;
      font-size: 1rem;
      border: none;
      &:focus {
        outline: none;
      }
    }
  }
  .results {
    background-color: white;
    height: 528px;
    overflow-y: scroll;
    margin-top: 1rem;

    &-item,
    &-empty {
      display: flex;
      cursor: pointer;
      font-size: 1rem;
      border: 1px solid white;
      align-items: center;
      padding: 0.5rem 1rem;
      justify-content: space-between;
      &.selected {
        color: #6b61f8;
        border: 1px solid #6b61f8;
      }
      .name-image {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
      .spends {
        display: flex;
        font-size: 0.7rem;
        margin-left: 1rem;
        div:first-child {
          margin-right: 0.5rem;
        }
      }

      &:hover {
        color: #6b61f8;
      }
      img {
        width: 50px;
        height: 50px;
        margin-right: 0.8rem;
      }
    }
  }
}

.snapchat-charts {
  margin-top: 1rem;
}

.font-size-10 {
  font-size: 10px;
}

.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}
.bg-white {
  background-color: white;
}

.font-size-1 {
  font-size: 1rem;
}
.font-size-2 {
  font-size: 2rem;
}

.mb-10 {
  margin-bottom: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-20 {
  margin-left: 20px;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.CalendarDay__selected_span {
  background: #bab5fa; //background
  color: white; //text
  border: 1px solid #bab5fa; //default styles include a border
}

.CalendarDay__selected {
  background: #6b61f8;
  color: white;
  border: 1px solid #6b61f8;
}

.CalendarDay__selected:hover {
  background: #5249c5;
  color: white;
  border: 1px solid #5249c5;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #bab5fa;
  color: white;
  border: 1px solid #bab5fa;
}

@import "styles/ArtistReport.scss";
@import "styles/ArtistEarnings.scss";
